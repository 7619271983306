import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
    currentRole: "",
    token: "",
  },
  reducers: {
    setLoginReducer: (state, action) => {
      state.currentUser = action.payload.currentUser;
      state.token = action.payload.token;
      state.currentRole = action.payload.currentUser.role[0];
    },
    setLogoutReducer: (state) => {
      state.currentUser = null;
      state.currentRole = "";
      state.token = "";
    },
    setRoleReducer: (state) => {
      if (state.currentRole === "Admin") {
        state.currentRole = state.currentUser.role.includes("Supervisor") ? "Supervisor" : "Employee";
      } else {
        if (state.currentUser.role.includes("Admin")) {
          state.currentRole = "Admin";
        }
      }
    }
  },
})

export const {
  setLoginReducer,
  setLogoutReducer,
  setRoleReducer,
} = userSlice.actions;

export default userSlice.reducer;