import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/attendance-request`;

const attendanceRequestApi = createApi({
  reducerPath: "attendanceRequest",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
    fetchFn: async (...args) => {
      return fetch(...args);
    },
  }),
  endpoints(builder) {
    return {
      fetchAttendanceRequest: builder.query({
        providesTags: (result, error) => {
          const tags = [];
          result && tags.push(...result.data.map((attendance) => ({
            type: "AttendanceRequest",
            id: attendance._id,
          })));
          tags.push({ type: "AttendanceRequestAll" });
          return tags;
        },
        query: (filter) => {
          return {
            url: `/?${Object.entries(filter || {}).map((val) => !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "").join("&")}`,
            method: "GET",
          };
        },
      }),
      fetchSingleAttendanceRequest: builder.query({
        providesTags: (result, error) => {
          const tags = [];
          tags.push({ type: "AttendanceRequestAll" });
          return tags;
        },
        query: (param) => {
          return {
            url: `/${param.id}`,
            method: "GET",
          };
        },
      }),
      addAttendanceRequest: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "AttendanceRequestAll" }];
        },
        query: (body) => {
          return {
            url: "/create",
            method: "POST",
            body: {
              uId: body.uId,
              punchIn: body.punchIn,
              punchOut: body.punchOut,
              notes: body.notes,
              punchInGps: body?.punchInGps,
              punchOutGps: body?.punchOutGps,
              punchInImage: body?.punchInImage,
              punchOutImage: body?.punchOutImage,
              punchInDesc: body?.punchInDesc,
              punchOutDesc: body?.punchOutDesc,
              breaks: body?.breaks,
            },
          };
        },
      }),
      updateAttendanceRequest: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "AttendanceRequestAll" }];
        },
        query: (body) => {
          return {
            url: `/${body.attendanceRequestID}`,
            method: "PUT",
            body: {
              bySupervisor: body.bySupervisor !== undefined ? {
                status: body.bySupervisor.status,
                comment: body.bySupervisor.comments,
              } : undefined,
              byHr: body.byHr !== undefined ? {
                status: body.byHr.status,
                comment: body.byHr.comments,
              } : undefined,
            },
          };
        },
      }),
    };
  },
});

export const {
  useFetchAttendanceRequestQuery,
  useFetchSingleAttendanceRequestQuery,
  useAddAttendanceRequestMutation,
  useUpdateAttendanceRequestMutation,
} = attendanceRequestApi;
export { attendanceRequestApi };
