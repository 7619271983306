import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import AppRouter from "routes";

import AlertMessage from "components/alertMessage";
import { setLoginReducer } from "store/slices/userSlice";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // first time set reducer if got localstorage
    const localStorageCurrentUser = JSON.parse(localStorage.getItem("currentUser"));
    const localStorageAccessToken = localStorage.getItem("accessToken");
    if (localStorageCurrentUser && localStorageAccessToken) {
      dispatch(setLoginReducer({ 
        currentUser: localStorageCurrentUser, 
        token: localStorageAccessToken, 
      }));
    } else {
      if (window.__POWERED_BY_QIANKUN__) {
        window.location.href = "/"
      }
    }
  }, []);

  return (
    <>
      <AppRouter />
      <AlertMessage />
    </>
  );
}

export default App;
