import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api`;

const externalApi = createApi({
  reducerPath: "external",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
  }),
  endpoints(builder) {
    return {
      fetchLocationDepartment: builder.query({
        query: (filter) => {
          return {
            url: `/employee/location`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useFetchLocationDepartmentQuery,
} = externalApi;
export { externalApi };
