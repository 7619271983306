import React, { useState, useEffect } from "react";
import {
  Button,
  Accordion,
  InputDate,
  InputSelect,
  InputTime,
  InputText,
  TextArea,
  ModalDialog,
} from "@bluesilodev/timhutcomponents";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from 'yup';

import { login } from "services/authApi";
import { updateAttendanceData } from "services/fetchAttendanceData";
import { createRequestAttendance } from "services/attendanceRequestService";
import { selectRecordByUId, updateEmployeeDetails } from "store/slices/attendanceDataSlice";
import { useAttendanceData } from "store/slices/attendanceDataSlice";
import { loginSuccess } from "store/slices/authSlice";
import { convertDateTimeToArray } from "utils/common";

// Own
import FetchingAndError from "components/fetchingAndError";
import { useAlertApi } from "services/alert-api";
import { useFetchSingleAttendanceQuery } from "store/apis/attendanceDataApi";
import { useAddAttendanceRequestMutation } from "store/apis/attendanceRequestApi";

const EditAttendance = () => {
  const { userID, uID } = useParams();
  const navigate = useNavigate();
  // Redux Toolkit
  const { data: data, error: errorAttendance, isFetching: isFetchingAttendance } = useFetchSingleAttendanceQuery({ id: uID }, { refetchOnMountOrArgChange: true });
  const [addAttendanceRequest, addAttendanceRequestResult] = useAddAttendanceRequestMutation();
  // Formik
  const formik = useFormik({
    initialValues: {
      employeeDetail: {
        photo: "",
        shift: "",
        status: "",
        firstName: "",
        lastName: "",
        userName: "",
        employeeID: "",
        userID: userID,
        location: "",
        department: "",
        jobPosition: "",
      },
      attendanceDetail: {
      },
      requestDetail: {
        punchInDate: "",
        punchInTime: "00:00",
        punchOutDate: "",
        punchOutTime: "00:00",
        punchInGps: {
          address: "",
          lat: "",
          long: "",
          postalCode: "",
        },
        punchOutGps: {
          address: "",
          lat: "",
          long: "",
          postalCode: "",
        },
        punchInImage: "",
        punchOutImage: "",
        punchInDesc: "",
        punchOutDesc: "",
        breaks: [
          {
            breakDate: "",
            returnFormBreakDate: "",
            breakTime: "00:00",
            returnFromBreak: "00:00",
            breakImage: "",
            returnImage: "",
            breakDesc: "",
            returnDesc: "",
            breakGps: {},
            returnFromBreakGps: {},
          },
        ],
      },
    },
    validationSchema: Yup.object().shape({
      requestDetail: Yup.object().shape({
        punchInDate: Yup.string().required('Punch In Date is required'),
        punchInTime: Yup.string().required('Punch In Time is required'),
        punchOutDate: Yup.string().required('Punch Out Date is required'),
        punchOutTime: Yup.string().required('Punch Out Time is required'),
        punchInGps: Yup.object().shape({
          address: Yup.string().required('Punch In Address is required'),
          lat: Yup.string(),
          long: Yup.string(),
          postalCode: Yup.string(),
        }),
        punchOutGps: Yup.object().shape({
          address: Yup.string().required('Punch Out Address is required'),
          lat: Yup.string(),
          long: Yup.string(),
          postalCode: Yup.string(),
        }),
        punchInImage: Yup.string().required('Punch In Image is required'),
        punchOutImage: Yup.string().required('Punch Out Image is required'),
        punchInDesc: Yup.string().required('Punch In Description is required'),
        punchOutDesc: Yup.string().required('Punch Out Description is required'),
        breaks: Yup.array().of(Yup.object().shape({
            breakDate: Yup.string().required('Break Date is required'),
            returnFormBreakDate: Yup.string().required('Return From Break Date is required'),
            breakTime: Yup.string().required('Break Time is required'),
            returnFromBreak: Yup.string().required('Return From Break Time is required'),
            breakImage: Yup.string().required('Break Image is required'),
            returnImage: Yup.string().required('Return From Break Image is required'),
            breakDesc: Yup.string().required('Break Description is required'),
            returnDesc: Yup.string().required('Return From Break Description is required'),
            breakGps: Yup.object().shape({}),
            returnFromBreakGps: Yup.object().shape({}),
        })),
      }),
    }),
    onSubmit: (values) => {
      if (values.attendanceDetail !== values.requestDetail) {
        const { punchInDate, punchInTime, punchOutDate, punchOutTime, breaks, ...rest } = values.requestDetail;
        const punchIn = new Date(punchInDate)
        const separatePunchInTime = punchInTime.split(":");
        punchIn.setHours(separatePunchInTime[0])
        punchIn.setMinutes(separatePunchInTime[1])
        const punchOut = new Date(punchOutDate)
        const separatePunchOutTime = punchOutTime.split(":");
        punchOut.setHours(separatePunchOutTime[0])
        punchOut.setMinutes(separatePunchOutTime[1])
        addAttendanceRequest({
          uId: uID,
          punchIn: punchIn,
          punchOut: punchOut,
          breaks: breaks.map((val) => {
            const { breakDate, breakTime, returnFormBreakDate, returnFromBreak, ...restBreaks } = val;
            const breakTimeRet = new Date(breakDate)
            const separateBreakTime = breakTime.split(":");
            breakTimeRet.setHours(separateBreakTime[0])
            breakTimeRet.setMinutes(separateBreakTime[1])
            const returnFromBreakRet = new Date(returnFormBreakDate)
            const separateReturnFromBreakTime = returnFromBreak.split(":");
            returnFromBreakRet.setHours(separateReturnFromBreakTime[0])
            returnFromBreakRet.setMinutes(separateReturnFromBreakTime[1])
            return {
              breakTime: breakTimeRet,
              returnFromBreak: returnFromBreakRet,
              ...restBreaks,
            }
          }),
          ...rest,
        })
      }
    },
  });

  React.useEffect(() => {
    if (data) {
      console.log(data.data)
      const punchIn = new Date(data.data.punchIn);
      const punchOut = new Date(data.data.punchOut);
      const attendanceData = {
        punchInDate: `${punchIn.getFullYear()}/${punchIn.getMonth() + 1}/${punchIn.getDate()}`,
        punchInTime: `${punchIn.getHours()}:${punchIn.getMinutes()}`,
        punchOutDate: `${punchOut.getFullYear()}/${punchOut.getMonth() + 1}/${punchOut.getDate()}`,
        punchOutTime: `${punchOut.getHours()}:${punchOut.getMinutes()}`,
        punchInGps: {
          address: data.data.punchInGps?.address,
          lat: "",
          long: "",
          postalCode: "",
        },
        punchOutGps: {
          address: data.data.punchOutGps?.address,
          lat: "",
          long: "",
          postalCode: "",
        },
        punchInImage: data.data.punchInImage,
        punchOutImage: data.data.punchOutImage,
        punchInDesc: data.data.punchInDesc,
        punchOutDesc: data.data.punchOutDesc,
        breaks: data.data.breaks.map((val) => {
          const breakDate = new Date(val.breakTime);
          const returnFromBreakDate = new Date(val.returnFromBreak);
          return {
            breakDate: `${breakDate.getFullYear()}/${breakDate.getMonth() + 1}/${breakDate.getDate()}`,
            returnFormBreakDate: `${returnFromBreakDate.getFullYear()}/${returnFromBreakDate.getMonth() + 1}/${returnFromBreakDate.getDate()}`,
            breakTime: `${breakDate.getHours()}:${breakDate.getMinutes()}`,
            returnFromBreak: `${returnFromBreakDate.getHours()}:${returnFromBreakDate.getMinutes()}`,
            breakImage: val.breakImage,
            returnImage: val.returnImage,
            breakDesc: val.breakDesc,
            returnDesc: val.returnDesc,
            breakGps: val.breakGps,
            returnFromBreakGps: val.returnFromBreakGps,
          };
        }),
      };
      formik.setFieldValue("employeeDetail", {
        photo: data.data.employeeDetails?.photo?.[0]?.link || "",
        shift: "-",
        status: data.data.status,
        firstName: data.data.employeeDetails?.firstName || "",
        lastName: data.data.employeeDetails?.lastName || "",
        userName: data.data.employeeDetails?.userName || "",
        employeeID: data.data.employeeDetails?.employeeID || "",
        userID: userID,
        location: data.data.employeeDetails?.locations?.address || "",
        department: data.data.employeeDetails?.userInformation?.employeementDetail?.departments || "",
        jobPosition: data.data.employeeDetails?.userInformation?.employeementDetail?.jobPosition || "",
      });
      formik.setFieldValue("attendanceDetail", attendanceData);
      formik.setFieldValue("requestDetail", attendanceData);
    }
  }, [data]);

  React.useEffect(() => {
    addAttendanceRequestResult.isSuccess && navigate(`/attendance-approval`)
  }, [addAttendanceRequestResult])

  useAlertApi(addAttendanceRequestResult);

  const dispatch = useDispatch();
  const [buttonLabel, setButtonLabel] = useState("Edit Attendance");
  const [activeInput, setActiveInput] = useState(false);
  const [required, setRequired] = useState(true);
  const [updatedData, setUpdatedData] = useState({});

  // const employeeDetails = useSelector((state) => selectRecordByUId(uId)(state));
  // const role = useSelector((state) => state.auth?.user?.role[0]);
  // const token = useSelector((state) => state.auth.token);

  const handleButtonClick = async () => {
    setActiveInput(!activeInput);

    /*
    if (role === "admin" || role === "supervisor") {
      try {
        setButtonLabel(buttonLabel === "Edit Attendance" ? "Save" : "Edit Attendance");

        // Logging updatedData before sending to backend
        console.log("Updated data:", updatedData);

        // Memperbarui employeeDetails dengan data yang diperbarui
        const updatedEmployeeDetails = updateNestedData(employeeDetails, updatedData);

        // Logging updated employeeDetails
        console.log("Updated employeeDetails:", updatedEmployeeDetails);

        // Mengirimkan data yang diperbarui ke backend
        await updateAttendanceData(token, uId, updatedEmployeeDetails)
          .then(response => {
            console.log("Server response:", response);
          });

        // Memanggil action creator untuk memperbarui data karyawan di Redux store
        dispatch(updateEmployeeDetails({ uId, updatedDetails: updatedEmployeeDetails }));

      } catch (error) {
        console.error("Error while updating admin or supervisor data:", error);
      }
    } else if (role === "employee") {
      try {

        setButtonLabel(buttonLabel === "Edit Attendance" ? "Request Changes" : "Edit Attendance");

        console.log("Updated data:", updatedData);

        const response = await createRequestAttendance(token, updatedData);

        console.log("Attendance change request created successfully:", response);

      } catch (error) {

        console.error("Error creating attendance change request:", error);

      }
    }
    */
  };

  useEffect(() => {
    // const email = "dion@gmail.com"; //employee
    // const email = "tony@gmail.com"; //supervisor
    const email = "willy@gmail.com"; //admin
    const password = "123456";

    const performLogin = async () => {
      try {
        const token = await login(email, password);
        dispatch(loginSuccess(token));
      } catch (error) {
        console.error("Login failed:", error);
      }
    };

    performLogin();
  }, [dispatch]);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  // Fungsi untuk memperbarui data secara rekursif
  const updateNestedData = (originalData, newData) => {
    const updatedData = { ...originalData };
    for (const prop in newData) {
      if (newData.hasOwnProperty(prop)) {
        if (typeof newData[prop] === "object" && !Array.isArray(newData[prop])) {
          updatedData[prop] = updateNestedData(originalData[prop], newData[prop]);
        } else {
          updatedData[prop] = newData[prop];
        }
      }
    }
    return updatedData;
  };

  // Fungsi untuk menangani perubahan input
  const handleInputChange = (e, key) => {
    console.log("Input changed:", e.target.value);
    setUpdatedData({ ...updatedData, [key]: e.target.value });
  };

  return (
    <>
      <div className="main p-1 mt-5">
        {/* Edit Attendance */}
        <div className="flex">
          <div className="">
            {!activeInput && (
                <Button
                label={buttonLabel}
                className={"w-[220px] h-[50px]"}
                style="solid"
                onClick={handleButtonClick}
              />
            )}
            {activeInput && (
              <Button
                label={"Save Attendance"}
                className={"w-[220px] h-[50px]"}
                style="solid"
                onClick={(e) => {
                  console.log("asd")
                  handleButtonClick()
                  formik.handleSubmit(e)
                }}
              />
            )}
          </div>
          <span className="pl-3" />
        </div>

        {/* Employee Detail */}
        <div className="mt-8">
          <Accordion title={<div>Employee Details</div>}>
            <div className="flex">
              <div className="self-center border border-[#A3A3AB] rounded-lg p-2">
                <img
                  className="object-cover rounded-[5px] w-[150px] h-[150px]"
                  src={formik.values.employeeDetail.photo}
                  alt="User Profile"
                  style={{
                    objectFit: "cover",
                  }}
                />
              </div>

              <div className="w-full ml-10">
                <div className="grid grid-cols-3 gap-6 ">
                  <div className="col-span-1">
                    <InputSelect
                      title={"Shift"}
                      options={[{ label: "", value: "" }]}
                      value={formik.values.employeeDetail.shift}
                      disabled={true}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputText
                      title={"Status"}
                      label={<></>}
                      classname={"h-[58px]"}
                      disabled={true}
                      value={formik.values.employeeDetail.status}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-6 mt-5">
                  <div className="col-span-1">
                    <InputText
                      title={"First Name"}
                      label={<></>}
                      classname={"h-[58px]"}
                      disabled={true}
                      value={formik.values.employeeDetail.firstName}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputText
                      title={"Last Name"}
                      label={<></>}
                      classname={"h-[58px]"}
                      disabled={true}
                      value={formik.values.employeeDetail.lastName}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputText
                      title={"Employee ID"}
                      label={<></>}
                      classname={"h-[58px]"}
                      disabled={true}
                      value={formik.values.employeeDetail.employeeID}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-6 mt-5">
                  <div className="col-span-1">
                    <InputSelect
                      title={"Location"}
                      options={[{ label: formik.values.employeeDetail.location, value: formik.values.employeeDetail.location }]}
                      value={formik.values.employeeDetail.location}
                      disabled={true}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputSelect
                      title={"Department"}
                      options={[{ label: formik.values.employeeDetail.department, value: formik.values.employeeDetail.department }]}
                      value={formik.values.employeeDetail.department}
                      disabled={true}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputSelect
                      title={"Job Position"}
                      options={[{ label: formik.values.employeeDetail.jobPosition, value: formik.values.employeeDetail.jobPosition }]}
                      value={formik.values.employeeDetail.jobPosition}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Accordion>
        </div>

        {/* Punch In */}
        <div className="mt-8">
          <Accordion title={<div>Punch In</div>}>
            <div className="flex">
              <div className="w-full">
                <div className="grid grid-cols-2 gap-6 ">
                  <div className="col-span-1">
                    <InputDate
                      label={"Punch In Date"}
                      classname={" h-[58px]"}
                      required={required}
                      value={formatDate(formik.values.requestDetail.punchInDate)}
                      disabled={!activeInput}
                      name={"requestDetail.punchInDate"}
                      setFieldValue={(_, val) => {
                        const splitDate = val.split("/");
                        const date = new Date(`${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`);
                        formik.setFieldValue("requestDetail.punchInDate", date);
                      }}
                    />
                  </div>
                  <div className="col-span-1 mr-6">
                    <InputTime
                      label={"Punch in Time"}
                      required={required}
                      value={formik.values.requestDetail.punchInTime}
                      disable={!activeInput}
                      setFieldValue={(_, val) => formik.setFieldValue("requestDetail.punchInTime", val)}
                    />
                  </div>
                  <div className="col-span-2 mr-6">
                    <InputText
                      title={"GPS Tracking"}
                      label={<></>}
                      classname={"h-[58px]"}
                      disabled={!activeInput}
                      placeholder={"Punch In GPS"}
                      value={formik.values.requestDetail.punchInGps.address}
                      name={"requestDetail.punchInGps.address"}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-span-2 mr-6">
                    <TextArea
                      required={false}
                      label={"Description"}
                      disable={!activeInput}
                      name={"requestDetail.punchInDesc"}
                      value={formik.values.requestDetail.punchInDesc}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="bg-gray-200 p-4 w-[400px] mr-10 rounded-md">
                <p className="text-xs font-bold">Attachment</p>
                <div className="mt-4">
                  <img
                    src={formik.values.requestDetail.punchInImage}
                    alt="Attachment"
                    className="w-[300px] h-[300px] rounded-lg"
                  />
                </div>
              </div>
            </div>
          </Accordion>
        </div>

        {/* Punch Out */}
        <div className="mt-8">
          <Accordion title={<div>Punch out</div>}>
            <div className="flex">
              <div className="w-full">
                <div className="grid grid-cols-2 gap-6 ">
                  <div className="col-span-1">
                    <InputDate
                      label={"Punch Out Date"}
                      classname={" h-[58px]"}
                      placeholder={"Punch Out"}
                      required={required}
                      disabled={!activeInput}
                      value={formatDate(formik.values.requestDetail.punchOutDate)}
                      name={"requestDetail.punchOutDate"}
                      setFieldValue={(_, val) => {
                        const splitDate = val.split("/");
                        const date = new Date(`${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`);
                        formik.setFieldValue("requestDetail.punchOutDate", date);
                      }}
                    />
                  </div>
                  <div className="col-span-1 mr-6">
                    <InputTime
                      label={"Punch Out Time"}
                      required={required}
                      disable={!activeInput}
                      value={formik.values.requestDetail.punchOutTime}
                      setFieldValue={(_, val) => formik.setFieldValue("requestDetail.punchOutTime", val)}
                    />
                  </div>

                  <div className="col-span-2 mr-6">
                    <InputText
                      title={"GPS Tracking"}
                      label={<></>}
                      classname={"h-[58px]"}
                      disabled={!activeInput}
                      placeholder={"Punch Out GPS"}
                      value={formik.values.requestDetail.punchOutGps.address}
                      name={"requestDetail.punchOutGps.address"}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-span-2 mr-6">
                    <TextArea
                      required={false}
                      label={"Description"}
                      disable={!activeInput}
                      name={"requestDetail.punchOutDesc"}
                      value={formik.values.requestDetail.punchOutDesc}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="bg-gray-200 p-4 w-[400px] mr-10 rounded-md">
                <p className="text-xs font-bold">Attachment</p>
                <div className="mt-4">
                  <img
                    src={formik.values.requestDetail.punchOutImage}
                    alt="Attachment"
                    className="w-[300px] h-[300px] rounded-lg"
                  />
                </div>
              </div>
            </div>
          </Accordion>
        </div>

        <FormikProvider value={formik}>
          {/* Break*/}
          <FieldArray
            name="requestDetail.breaks"
            render={(arrayHelpers) => (
              <React.Fragment>
                {formik.values.requestDetail.breaks.map((breakItem, index) => (
                  <div className="mt-8">
                    <Accordion title={<div>{`Break #${index + 1}`}</div>} key={breakItem._id}>
                      <div className="flex">
                        <div className="w-full">
                          <div className="grid grid-cols-2 gap-6 ">
                            <div className="col-span-1">
                              <InputDate
                                label={"Break Date"}
                                classname={" h-[58px]"}
                                placeholder={"Break Date"}
                                required={required}
                                value={formatDate(breakItem.breakDate)}
                                disabled={!activeInput}
                                name={`requestDetail.breaks[${index}].breakDate`}
                                setFieldValue={(_, val) => {
                                  const splitDate = val.split("/");
                                  const date = new Date(`${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`);
                                  formik.setFieldValue(`requestDetail.breaks[${index}].breakDate`, date);
                                }}
                              />
                            </div>
                            <div className="col-span-1 mr-6">
                              <InputTime
                                label={"Break Time"}
                                required={required}
                                value={breakItem.breakTime}
                                disable={!activeInput}
                                setFieldValue={(_, val) => formik.setFieldValue(`requestDetail.breaks[${index}].breakTime`, val)}
                              />
                            </div>
                            <div className="col-span-2 mr-6">
                              <InputText
                                title={"GPS Tracking"}
                                label={<></>}
                                classname={"h-[58px]"}
                                disabled={!activeInput}
                                placeholder={"Break GPS"}
                                value={breakItem.breakGps?.address}
                                name={`requestDetail.breaks[${index}].breakGps.address`}
                                onChange={formik.handleChange}
                              />
                            </div>
                            <div className="col-span-2 mr-6 h-[100px]">
                              <TextArea
                                label={"Description"}
                                required={false}
                                disable={!activeInput}
                                name={`requestDetail.breaks[${index}].breakDesc`}
                                value={breakItem.breakDesc}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-200 p-4 w-[400px] mr-10 rounded-md">
                          <p className="text-xs font-bold">Attachment</p>
                          <div className="mt-4">
                            <img
                              src={breakItem.breakImage}
                              alt="Attachment"
                              className="w-[300px] h-[300px] rounded-lg"
                            />
                          </div>
                        </div>
                      </div>
                    </Accordion>
                  </div>
                ))}
              </React.Fragment>
            )}>
          </FieldArray>

          {/* Resume*/}
          <FieldArray
            name="requestDetail.breaks"
            render={(arrayHelpers) => (
              <React.Fragment>
                {formik.values.requestDetail.breaks.map((breakItem, index) => (
                  <div className="mt-8 mb-8">
                    <Accordion title={<div>{`Resume #${index + 1}`}</div>} key={breakItem._id}>
                      <div className="flex">
                        <div className="w-full">
                          <div className="grid grid-cols-2 gap-6 ">
                            <div className="col-span-1">
                              <InputDate
                                label={"Resume Date"}
                                classname={" h-[58px]"}
                                placeholder={"Resume Date"}
                                value={formatDate(breakItem.returnFormBreakDate)}
                                disabled={!activeInput}
                                required={required}
                                name={`requestDetail.breaks[${index}].returnFormBreakDate`}
                                setFieldValue={(_, val) => {
                                  const splitDate = val.split("/");
                                  const date = new Date(`${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`);
                                  formik.setFieldValue(`requestDetail.breaks[${index}].returnFormBreakDate`, date);
                                }}
                              />
                            </div>
                            <div className="col-span-1 mr-6">
                              <InputTime
                                label={"Resume Time"}
                                value={breakItem.returnFromBreak}
                                required={required}
                                disable={!activeInput}
                                setFieldValue={(_, val) => formik.setFieldValue(`requestDetail.breaks[${index}].returnFromBreak`, val)}
                              />
                            </div>
                            <div className="col-span-2 mr-6">
                              <InputText
                                title={"GPS Tracking"}
                                label={<></>}
                                classname={"h-[58px]"}
                                disabled={!activeInput}
                                placeholder={"Resume GPS"}
                                value={breakItem.returnFromBreakGps?.address}
                                name={`requestDetail.breaks[${index}].returnFromBreakGps.address`}
                                onChange={formik.handleChange}
                              />
                            </div>
                            <div className="col-span-2 mr-6">
                              <TextArea
                                label={"Description"}
                                required={false}
                                disable={!activeInput}
                                name={`requestDetail.breaks[${index}].returnDesc`}
                                value={breakItem.returnDesc}
                                onChange={formik.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-200 p-4 w-[400px] mr-10 rounded-md">
                          <p className="text-xs font-bold">Attachment</p>
                          <div className="mt-4">
                            <img
                              src={breakItem.returnImage}
                              alt="Attachment"
                              className="w-[300px] h-[300px] rounded-lg"
                            />
                          </div>
                        </div>
                      </div>
                    </Accordion>
                  </div>
                ))}
              </React.Fragment>
            )}>
          </FieldArray>
        </FormikProvider>
      </div>
    </>
  );
};

export default EditAttendance;
