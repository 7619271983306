import React from "react";
import {
  InputSelect,
  Button,
  InputDate,
  InputTime,
  ModalDialog,
  TextArea,
} from "@bluesilodev/timhutcomponents";
import { useFormikContext } from "formik";

export const AttendanceOvertimeRequestOvertimeModalForm = ({
  open: isModalOpen,
  handleClose: handleCloseModal,
}) => {
  const formik = useFormikContext();
  const label = <span className="text-gray-500 text-xs">Notes</span>;
  const setDigit = (number) => {
    if (number > 9) return number
    else return `0${number}`
  }
  return (
    <React.Fragment>
      {isModalOpen && (
        <ModalDialog
          title="Request Overtime"
          onClose={handleCloseModal}
          onSubmit={formik.handleSubmit}
          className={"w-3/4 p-6 max-h-[90%]"}
        >
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-1">
              <InputSelect
                title={"Locations"}
                options={[{ label: "Cafe Halim", value: "Cafe Halim" }]}
                value={"Cafe Halim"}
                disabled={true}
                classname={"h-[58px]"}
              />
            </div>
            <div className="col-span-1">
              <InputSelect
                title={"Departments"}
                options={[{ label: "Operations", value: "Operations" }]}
                value={"Operations"}
                disabled={true}
                classname={"h-[58px]"}
              />
            </div>
            <div className="col-span-1">
              <InputSelect
                title={"Job Positions"}
                options={[{ label: "Supervisor", value: "Supervisor" }]}
                value={"Supervisor"}
                disabled={true}
                classname={"h-[58px]"}
              />
            </div>
            <div className="col-span-1">
              <InputSelect
                title={"Employee Name"}
                options={[{ label: "Supervisor", value: "Supervisor" }]}
                value={"Supervisor"}
                disabled={true}
                classname={"h-[58px]"}
              />
            </div>
            <div className="col-span-1">
              <InputSelect
                title={"Shift"}
                options={[{ label: "Office Working Hour", value: "Office Working Hour" }]}
                value={"Office Working Hour"}
                disabled={true}
                classname={"h-[58px]"}
              />
            </div>
            <div className="col-span-1">
              <InputDate
                label={"Punch In Date"}
                value={`${setDigit(formik.values.overtimeDate.getDate())}/${setDigit(formik.values.overtimeDate.getMonth() + 1)}/${formik.values.overtimeDate.getFullYear()}`}
                errors={formik.errors.attendanceID}
                setFieldValue={(_, event) => {
                  const splitDate = event.split("/");
                  const convertToDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
                  formik.setFieldValue("overtimeDate", convertToDate);
                }}
              />
            </div>
            <div className="col-span-1">
              <InputTime label={"Punch In Time"} disable={true} value={formik.values.attendancePunchInTime} />
            </div>
            <div className="col-span-1">
              <InputTime label={"Punch Out Time"} disable={true} value={formik.values.attendancePunchOutTime} />
            </div>
            <div className="col-span-1">
              <InputTime label={"Duration Of Overtime"} value={formik.values.overtimeDuration} setFieldValue={(_, val) => formik.setFieldValue("overtimeDuration", val)} />
            </div>
            <div className="col-span-3 row-span-2">
              <TextArea label={label} name="notes" value={formik.values.notes} onChange={formik.handleChange} />
              <div className="text-red-500">{formik.errors.notes}</div>
            </div>
          </div>
          <div className="flex justify-between mt-6">
            <Button
              label={"Cancel"}
              onClick={handleCloseModal}
              className={"flex-grow mr-2"}
            />
            <Button
              label={"Request Overtime"}
              onClick={(e) => !formik.errors.overtimeDate && formik.handleSubmit(e)}
              className={"flex-grow "}
              style="solid"
            />
          </div>
        </ModalDialog>
      )}
    </React.Fragment>
  );
};
