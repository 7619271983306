import React from "react";
import {
  DataTable,
  Pagination,
  ColorTag,
  TableMenu,
} from "@bluesilodev/timhutcomponents";
import DateDisplay from "components/formatedDateTime";

export const AttendanceOvertimeDataTable = ({
  dataTable,
  totalData,
  rowsPerPage,
  currentPage,
  onChangeCurrentPage,
  onChangeRowsPerPage,
  onClickRedirect,
}) => {
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  };
  return (
    <React.Fragment>
      <DataTable
        title="Attendance Data"
        columns={[
          {
            id: "employee_name",
            accessorFn: (row) => row,
            header: () => <span>Employee</span>,
            enableSorting: true,
            noPadding: true,
            cell: (status) => {
              const { employeeDetails: employee} = status.getValue();
              return (
                <div className="flex flex-row gap-2 justify-center items-center ">
                  <img
                    src={employee?.photo?.[0]?.link || ""}
                    alt="ProfileUser"
                    className="w-6 h-6 my-auto ml-4 rounded-full"
                  />
                  <span>{employee?.userName || ""}</span>
                </div>
              );
            },
          },
          {
            id: "job_position",
            accessorFn: (row) => row,
            header: () => <span>Job Position</span>,
            enableSorting: true,
            noPadding: true,
            cell: (status) => {
              const { employeeDetails: employee } = status.getValue();
              return (
                <div className="w-full cursor-pointer">
                  <h1 className="">{employee?.userInformation?.employeementDetail?.jobPosition || ""}</h1>
                  <h1 className="text-s text-gray-400">{employee?.userInformation?.employeementDetail?.departments || ""}</h1>
                </div>
              );
            },
          },
          {
            id: "request_date",
            accessorFn: (row) => row.createdAt,
            header: () => <span>Request Date</span>,
            enableSorting: true,
            noPadding: true,
            cell: (date) => {
              const dateStr = date.getValue();
              return <DateDisplay dateStr={dateStr} />;
            },
          },
          {
            id: "overtime_date",
            accessorFn: (row) => row.overtimeDate,
            header: () => <span>Overtime Date</span>,
            enableSorting: true,
            cell: (date) => {
              const dateStr = date.getValue();
              return <div> {formatDate(dateStr)}</div>;
            },
          },
          {
            id: "scheduleID",
            accessorFn: (row) => "-",
            header: () => <span>Shift</span>,
            enableSorting: true,
          },
          {
            id: "ot_duration",
            accessorFn: (row) => row.overtimeDuration,
            header: () => <span>Overtime Duration</span>,
            enableSorting: true,
            cell: (value) => {
              return (
                <>
                  {value.getValue()} Minutes
                </>
              )
            },
          },
          {
            id: "approval_by_supervisor",
            header: () => <span>Approval by Supervisor</span>,
            accessorFn: (row) => row.approvalStatus,
            enableSorting: false,
            noPadding: true,
            cell: (status) => {
              const value = status.getValue();
              const approvalStatus = value.bySupervisor.status;
              if (approvalStatus === "Pending") {
                return (
                  <>
                    <div className=" font-semibold text-white cursor-pointer">
                      <ColorTag
                        label="Pending"
                        color="gray"
                        width={"150px"}
                        height={"30px"}
                      />
                    </div>
                  </>
                );
              }
              if (approvalStatus === "Rejected") {
                return (
                  <>
                    <div className="font-semibold text-white cursor-pointer">
                      <ColorTag label={approvalStatus} color="red" />
                    </div>
                  </>
                );
              }
              if (approvalStatus === "Approved") {
                return (
                  <>
                    <div className=" font-semibold text-white cursor-pointer">
                      <ColorTag label={approvalStatus} color="green" />
                    </div>
                  </>
                );
              }
            },
          },
          {
            id: "approval_by_admin",
            header: () => <span>Approval by HR Admin</span>,
            accessorFn: (row) => row.approvalStatus,
            enableSorting: false,
            noPadding: true,
            cell: (status) => {
              const value = status.getValue();
              const approvalStatus = value.byHr.status;
              if (approvalStatus === "Pending") {
                return (
                  <>
                    <div className=" font-semibold text-white cursor-pointer">
                      <ColorTag
                        label="Pending"
                        color="gray"
                        width={"150px"}
                        height={"30px"}
                      />
                    </div>
                  </>
                );
              }
              if (approvalStatus === "Rejected") {
                return (
                  <>
                    <div className="font-semibold text-white cursor-pointer">
                      <ColorTag label={approvalStatus} color="red" />
                    </div>
                  </>
                );
              }
              if (approvalStatus === "Approved") {
                return (
                  <>
                    <div className=" font-semibold text-white cursor-pointer">
                      <ColorTag label={approvalStatus} color="green" />
                    </div>
                  </>
                );
              }
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            noPadding: true,
            cell: (status) => {
              const rowData = status.getValue();
              return (
                <div className="cursor-pointer w-fit">
                  <TableMenu
                    show={true}
                    onDetail={() => { onClickRedirect(`/attendance-overtime/overtime-approval/${rowData.userID}/${rowData.uId}`) }}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={false}
      />
      <div className="py-2" />
      <Pagination
        totalData={totalData}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onChangeCurrentPage={onChangeCurrentPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </React.Fragment>
  );
};
