import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/attendance`;

const attendanceDataApi = createApi({
  reducerPath: "attendanceData",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
    fetchFn: async (...args) => {
      return fetch(...args);
    },
  }),
  endpoints(builder) {
    return {
      fetchAttendance: builder.query({
        providesTags: (result, error) => {
          const tags = [];
          result && tags.push(...result.data.map((attendance) => ({
            type: "Attendance",
            id: attendance._id,
          })));
          tags.push({ type: "AttendanceAll" });
          return tags;
        },
        query: (filter) => {
          return {
            url: `/?status=${filter.masterType}&${Object.entries(filter || {}).map((val) => !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "").join("&")}`,
            method: "GET",
          };
        },
      }),
      fetchSingleAttendance: builder.query({
        providesTags: (result, error) => {
          const tags = [];
          tags.push({ type: "AttendanceAll" });
          return tags;
        },
        query: (param) => {
          return {
            url: `/${param.id}`,
            method: "GET",
          };
        },
      }),
      fetchSingleAttendanceByDateUserID: builder.query({
        query: (filter) => {
          return {
            url: `/get-by-date?${Object.entries(filter || {}).map((val) => !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "").join("&")}`,
            method: "GET",
          };
        },
      }),
      addAttendanceRequest: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "AttendanceAll" }];
        },
        query: (body) => {
          return {
            url: "/create",
            method: "POST",
            body: {
              uId: body.uId,
              punchIn: body.punchIn,
              punchOut: body.punchOut,
              notes: body.notes,
            },
          };
        },
      }),
      addPunchInAttendance: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "AttendanceAll" }];
        },
        query: (body) => {
          const formData = new FormData();
          body.attachments && [...body.attachments].forEach((value) => {
            formData.append("punchInImage", value);
          });
          return {
            url: "/punch-in",
            method: "POST",
            body: formData,
          };
        },
      }),
    };
  },
});

export const {
  useFetchAttendanceQuery,
  useFetchSingleAttendanceQuery,
  useFetchSingleAttendanceByDateUserIDQuery,
  useAddAttendanceRequestMutation,
  useAddPunchInAttendanceMutation,
} = attendanceDataApi;
export { attendanceDataApi };
