import React from "react";
import {
  DataTable,
  Pagination,
  ColorTag,
  TableMenu,
} from "@bluesilodev/timhutcomponents";
import DateDisplay from "components/formatedDateTime";

export const AttendanceDataDataTable = ({
  dataTable,
  totalData,
  rowsPerPage,
  currentPage,
  onChangeCurrentPage,
  onChangeRowsPerPage,
  onClickRedirect,
}) => {
  // Calculated Total Hours
  const calculateTotalHours = (punchIn, punchOut) => {
    if (!punchOut) {
      return "0 Hours 0 minutes";
    }
    const punchInTime = new Date(punchIn);
    const punchOutTime = new Date(punchOut);
    const timeDifference = punchOutTime - punchInTime;
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    return `${hours} Hours ${minutes} minutes`;
  };
  return (
    <React.Fragment>
      <DataTable
        title="Attendance Data"
        columns={[
          {
            id: "employee_name",
            accessorFn: (row) => row,
            header: () => <span>Employee</span>,
            enableSorting: true,
            noPadding: true,
            cell: (status) => {
              const { employeeInfo: employee} = status.getValue();
              return (
                <div className="flex flex-row gap-2 justify-center items-center ">
                  <img
                    src={employee?.photo?.[0]?.link || ""}
                    alt="ProfileUser"
                    className="w-6 h-6 my-auto ml-4 rounded-full"
                  />
                  <span>{employee?.userName || ""}</span>
                </div>
              );
            },
          },
          {
            id: "employeeID",
            accessorFn: (row) => row.employeeInfo?.employeeID || "-",
            header: () => <span>Employee ID</span>,
            enableSorting: true,
          },
          {
            id: "job_position",
            accessorFn: (row) => row,
            header: () => <span>Job Position</span>,
            enableSorting: true,
            noPadding: true,
            cell: (status) => {
              const { employeeInfo: employee } = status.getValue();
              return (
                <div className="w-full cursor-pointer">
                  <h1 className="">{employee?.userInformation?.employeementDetail?.jobPosition || ""}</h1>
                  <h1 className="text-s text-gray-400">{employee?.userInformation?.employeementDetail?.departments || ""}</h1>
                </div>
              );
            },
          },
          {
            id: "scheduleID",
            accessorFn: (row) => row.scheduleID,
            header: () => <span>Shift</span>,
            enableSorting: true,
          },
          {
            id: "punchIn",
            accessorFn: (row) => row.punchIn,
            header: () => <span>Punch In</span>,
            noPadding: true,
            cell: (date) => {
              const dateStr = date.getValue();
              return <DateDisplay dateStr={dateStr} />;
            },
            enableSorting: true,
          },
          {
            id: "punchOut",
            accessorFn: (row) => row.punchOut,
            header: () => <span>Punch Out</span>,
            noPadding: true,
            cell: (date) => {
              const dateStr = date.getValue();
              return <DateDisplay dateStr={dateStr} />;
            },
            enableSorting: true,
          },
          {
            id: "totalHours",
            accessorFn: (row) =>
              calculateTotalHours(row.punchIn, row.punchOut),
            header: () => <span>Total Hours</span>,
            enableSorting: true,
          },
          {
            id: "status",
            accessorFn: (row) => ({
              uId: row.uId,
              status: row.status,
            }),
            header: () => <span>Status</span>,
            enableSorting: false,
            noPadding: true,
            cell: (status) => {
              const { uId, status: recordStatus } = status.getValue();
              let color = "";
              if (recordStatus === "On Time") {
                color = "green";
              } else if (recordStatus === "Late") {
                color = "red";
              } else if (recordStatus === "Absence") {
                color = "orange";
              } else if (recordStatus === "No Punch In Out") {
                color = "blue";
              } else if (recordStatus === "On Leave") {
                color = "gray";
              } else if (recordStatus === "Overtime") {
                color = "green";
              } else {
                color = "orange";
              }
              return (
                <>
                  <div className="flex justify-center items-center font-semibold text-white cursor-pointer">
                    <ColorTag
                      label={recordStatus}
                      color={color}
                      width={"150px"}
                      height={"30px"}
                    />
                  </div>
                </>
              );
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            noPadding: true,
            cell: (status) => {
              const rowData = status.getValue();
              return (
                <div className="cursor-pointer w-fit">
                  <TableMenu
                    show={true}
                    onDetail={() => { onClickRedirect(`/attendance-data/${rowData.userID}/${rowData.uId}`) }}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={false}
      />
      <div className="py-2" />
      <Pagination
        totalData={totalData}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onChangeCurrentPage={onChangeCurrentPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </React.Fragment>
  );
};
